import React from "react";
import { ic_error_outline } from "react-icons-kit/md/ic_error_outline";
import Icon from "react-icons-kit";
export const ErrorMessage = props => {
  return (
    <div className="error-container">
      <div className="row">
        <div className="col-md-1">
          <Icon icon={ic_error_outline} className="icon" size={24} />
        </div>
        <div className="col-md-10">
          <div className="error-line">{props.children}</div>
          {/* <div className="error">{props.children}</div> */}
        </div>
      </div>
    </div>
  );
};
