const DEBIT_CATEGORIES = {
  "DEBIT CHEQUE GROUP": {
    label: "DEBIT CHEQUE GROUP",
    color: "rgb(48 83 255)",
  },
  "DEBIT VENDOR PAYMENTS": {
    label: "DEBIT VENDOR PAYMENTS",
    color: "rgb(0 16 97)",
  },
  "DEBIT TAX PAYMENTS": {
    label: "DEBIT TAX PAYMENTS",
    color: "rgb(0 154 142)",
  },
  "DEBIT SALARY GROUP": {
    label: "DEBIT SALARY GROUP",
    color: "rgb(0 205 190)",
  },
  "DEBIT EMI REPAYMENT GROUP": {
    label: "DEBIT EMI REPAYMENT GROUP",
    color: "rgb(72 255 242)",
  },
  "DEBIT INTEREST TO BANK GROUP": {
    label: "DEBIT INTEREST TO BANK GROUP",
    color: "rgb(125 220 213)",
  },
  "DEBIT STATUTORY PAYMENT": {
    label: "DEBIT STATUTORY PAYMENT",
    color: "rgb(194 255 251)",
  },
  "DEBIT UTILITIES EXPENSES GROUP": {
    label: "DEBIT UTILITIES EXPENSES GROUP",
    color: "rgb(188 188 188)",
  },
  "DEBIT CASH WITHDRAWL GROUP": {
    label: "DEBIT CASH WITHDRAWL GROUP",
    color: "rgb(152 169 255)",
  },
  "DEBIT INVESTMENT MADE GROUP": {
    label: "DEBIT INVESTMENT MADE GROUP",
    color: "rgb(164 223 247)",
  },
  "DEBIT BANK CHARGES GROUP": {
    label: "DEBIT BANK CHARGES GROUP",
    color: "rgb(14 117 159)",
  },
  "DEBIT SELF GROUP": {
    label: "DEBIT SELF GROUP",
    color: "rgb(103 201 242)",
  },
  "DEBIT OUTWARD BOUNCE GROUP": {
    label: "DEBIT OUTWARD BOUNCE GROUP",
    color: "rgb(78 73 171)",
  },
  "DEBIT REVERSAL GROUP": {
    label: "DEBIT REVERSAL GROUP",
    color: "rgb(78 73 171)",
  },
};

const CREDIT_CATEGORIES = {
  "CREDIT CUSTOMER PAYMENTS": {
    label: "CREDIT CUSTOMER PAYMENTS",
    color: "rgb(0 16 97)",
  },
  "CREDIT CHEQUE GROUP": {
    label: "CREDIT CHEQUE GROUP",
    color: "rgb(48 83 255)",
  },
  "CREDIT CASH DEPOSIT": {
    label: "CREDIT CASH DEPOSIT",
    color: "rgb(152 169 255)",
  },

  "CREDIT LOAN AND ADVANCES": {
    label: "CREDIT LOAN AND ADVANCES",
    color: "rgb(0 205 190)",
  },
  "CREDIT INVESTMENT MATURED": {
    label: "CREDIT INVESTMENT MATURED",
    color: "rgb(125 220 213)",
  },
  "CREDIT TAX REFUND": {
    label: "CREDIT TAX REFUND",
    color: "rgb(194 255 251)",
  },
  "CREDIT INWARD BOUNCE": {
    label: "CREDIT INWARD BOUNCE",
    color: "rgb(78 73 171)",
  },
  "CREDIT SELF GROUP": {
    label: "CREDIT SELF GROUP",
    color: "rgb(103 201 242)",
  },
  "CREDIT REVERSAL": {
    label: "CREDIT REVERSAL",
    color: "rgb(164 223 247)",
  },
};

const GRAPHS = {
  debitCashFlowQuarter: {
    name: "DEBIT_INFLOW_CHART",
    propsKey: "debits",
  },
  creditCashFlowQuarter: {
    name: "CREDIT_INFLOW_CHART",
    propsKey: "credits",
  },
};

const Quarters = {
  firstQuarter: ["Jan", "Feb", "Mar"],
  secondQuarter: ["Apr", "May", "Jun"],
  thirdQuarter: ["Jul", "Aug", "Sep"],
  fourthQuarter: ["Oct", "Nov", "Dec"],
  wholeYear: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
};

const getColorCode = (type, value) => {
  let colorCode = "";
  if (type === "credit") {
    Object.keys(CREDIT_CATEGORIES).forEach((keyName) => {
      const obj = CREDIT_CATEGORIES[keyName];
      if (obj.label.toLowerCase() === value.toLowerCase()) {
        colorCode = obj.color;
      }
    });
  } else if (type === "debit") {
    Object.keys(DEBIT_CATEGORIES).forEach((keyName) => {
      const obj = DEBIT_CATEGORIES[keyName];
      if (obj.label.toLowerCase() === value.toLowerCase()) {
        colorCode = obj.color;
      }
    });
  }
  return colorCode;
};

export { DEBIT_CATEGORIES, CREDIT_CATEGORIES, GRAPHS, Quarters, getColorCode };
